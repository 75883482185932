import Masonry from 'masonry-layout';
import GLightbox from 'glightbox';
import LazyLoad from 'vanilla-lazyload';
import Prefixfree from 'prefixfree';
import CookiesEuBanner from 'cookies-eu-banner';

// var elem = document.querySelector('.grid');
// var msnry = new Masonry( '.grid', {
//     columnWidth: 200
//   });



window.onload = () => {
  //pre loader
  var preLoader = document.getElementById("pre-loader");
  preLoader.classList.add("fade-hide");

  //masonry
  const grid = document.querySelector('.grid');

  if (grid) {
    const masonry = new Masonry(grid, {
      itemSelector: '.grid-item',
      columnWidth: 600,
      fitWidth: true,
      gutter: 20,
    });
  }
}

//lazyload
var lazyLoadInstance = new LazyLoad({});

//lightbox
const lightbox = GLightbox({
  loop: true,
  zoomable: false,
  draggable: false
});

//scroll
let scrollpos = window.scrollY
const header = document.querySelector(".head")
const mainHeader = document.getElementById('main-header');

if (header) {
  const header_height = header.offsetHeight

  window.addEventListener('scroll', function () {
    scrollpos = window.scrollY;

    if (scrollpos >= header_height) {
      mainHeader.classList.remove('hide');
    } else {
      mainHeader.classList.add('hide');
    }
  })
}

//burger menu
const burgerMenu = document.getElementById('burgerMenu');
const pageList = document.getElementById('page-list');
const headerNav = document.getElementById('header-nav');

burgerMenu.addEventListener('click', e => {
  burgerMenu.classList.toggle('open');
  headerNav.classList.toggle('open');
})

//cookie banner
new CookiesEuBanner(function () {
  // Your code to launch when user accept cookies
}, true);